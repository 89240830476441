import { graphql } from "msw";
import casual from "casual-browserify";

export const getErrorResponse = (operationName: string) =>
  graphql.mutation(operationName, (_req, res, ctx) => {
    return res.once(
      ctx.errors([
        {
          message: "Internal Server Error",
          locations: [
            {
              line: 8,
              column: 12,
            },
          ],
        },
      ])
    );
  });

export const getPresignedUrlResponse = graphql.query(
  "GetPresignedUrl",
  (_req, res, ctx) => {
    return res(
      ctx.data({
        getPresignedUrl: {
          fileName: casual.string,
          httpMethod: "POST",
          url: casual.url,
        },
      })
    );
  }
);
export const getImportResponse = graphql.query(
  "GetImport",
  (_req, res, ctx) => {
    return res(
      ctx.data({
        getImport: {
          id: casual.integer(1, 10),
          createdOn: casual.date(),
          createdBy: casual.date(),
          creationSource: casual.string,
          errorRows: casual.integer(1, 10),
          successRows: casual.integer(1, 10),
          totalRows: casual.integer(1, 10),
          state: casual.string,
          resourceLocation: casual.string,
          errorsResourceLocation: casual.string,
        },
      })
    );
  }
);

export const GetAllValidationsResponse = graphql.query(
  "GetAllValidations",
  (_req, res, ctx) => {
    return res(
      ctx.data({
        getAllValidations: {
          pageDetails: {
            page: 1,
            pageSize: 5,
            totalEntityCount: 10,
            totalPageCount: 2,
          },
          validations: [
            {
              stockNumber: casual.integer(),
              input: casual.string,
              errors: casual.array_of_words(),
            },
          ],
        },
      })
    );
  }
);

export const searchRetailDataImportsResponse = graphql.query(
  "searchRetailDataImportsResponse",
  (_req, res, ctx) => {
    return res(
      ctx.data({
        searchRetailDataImports: {
          pageDetails: {
            page: 1,
            pageSize: 1,
            totalEntityCount: 1,
            totalPageCount: 1,
          },
          imports: [
            {
              id: casual.integer(1, 10),
              createdOn: casual.date(),
              createdBy: casual.date(),
              creationSource: casual.string,
              errorRows: casual.integer(1, 10),
              successRows: casual.integer(1, 10),
              totalRows: casual.integer(1, 10),
              state: casual.string,
              resourceLocation: casual.string,
              errorsResourceLocation: casual.string,
            },
          ],
        },
      })
    );
  }
);

export const applyAdPriceUpdateResponse = graphql.mutation(
  "ApplyAdPriceUpdate",
  (_req, res, ctx) => {
    return res(
      ctx.data({
        applyAdPriceUpdate: true,
      })
    );
  }
);

export const applyAdPriceUpdateErrorResponse =
  getErrorResponse("ApplyAdPriceUpdate");

export const getPermissionsResponse = graphql.query(
  "GetPermissions",
  (_req, res, ctx) => {
    return res(
      ctx.data({
        permissions: {
          canApplyAdPriceUpdates: true,
          canApplyReturnToAuto1Updates: false,
        },
      })
    );
  }
);

export const createAdPriceBatchImportResponse = graphql.mutation(
  "CreateAdPriceBatchImport",
  (_req, res, ctx) => {
    return res(
      ctx.data({
        createAdPriceBatchImport: {
          id: casual.uuid,
        },
      })
    );
  }
);
export const handlers = [
  getPresignedUrlResponse,
  getImportResponse,
  searchRetailDataImportsResponse,
  applyAdPriceUpdateResponse,
  getPermissionsResponse,
  createAdPriceBatchImportResponse,
];
